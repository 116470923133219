import { AuthProvider } from 'react-admin';

const authProvider: AuthProvider = {
    login: async ({username, password}) => {
        const request = new Request('/api/login', {
            method: 'POST',
            body: JSON.stringify({username: username, password: password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });
        try {
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                new Error(response.statusText);
            }
            const auth = await response.json();
            localStorage.setItem('auth', JSON.stringify(auth));
            localStorage.setItem('auth', JSON.stringify(auth));
        } catch {
            throw new Error('Network error');
        }
    },
    logout: async () => {
        const request = new Request('/api/logout', {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json'}),
        });
        try {
            localStorage.removeItem('auth');
            const response =  await fetch(request);
            return await response.json();
        } catch {
            throw new Error('Network error');
        }
    },
    checkAuth: () =>
        localStorage.getItem('auth') ? Promise.resolve() : Promise.reject(),
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>{
        try {
            const { id, fullName, avatar } = JSON.parse(localStorage.getItem('auth') ?? '');
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => Promise.resolve(''),
};

export default authProvider;
