import {Datagrid, List, ReferenceArrayField, TextField, Show, SimpleShowLayout, ShowButton} from 'react-admin';

export const GroupList = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" />
            <ReferenceArrayField source="services_ids" reference="service" />
        </Datagrid>
    </List>
);

export const GroupShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="name" />
            <ReferenceArrayField label="Account" reference="account" source="accounts_ids">
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
            <ReferenceArrayField label="Service" reference="service" source="services_ids">
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);
