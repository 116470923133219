import React from 'react';
import { Admin, Resource } from 'react-admin';
import { DashboardDataProvider } from './dataProvider';
import { AccountList, AccountShow } from './models/account';
import { GroupList, GroupShow } from './models/group';
import { HostCreate, HostEdit, HostList, HostShow } from './models/host';
import { AddByEmailsForm, ServiceCreate, ServiceEdit, ServiceList, ServiceShow } from './models/service';
import authProvider from './authProvider';
import { Route } from 'react-router-dom';

const dataProvider = new DashboardDataProvider('/api');

function App() {
    return (
        <Admin disableTelemetry dataProvider={ dataProvider } authProvider={ authProvider }>
            <Resource name="account" list={ AccountList } show={ AccountShow }/>
            <Resource name="group" list={ GroupList } show={ GroupShow }  recordRepresentation="title"/>
            <Resource name="service" list={ ServiceList } show={ ServiceShow } edit={ ServiceEdit } create={ ServiceCreate }>
                <Route path=":id/add-by-emails" element={<AddByEmailsForm />} />
            </Resource>
            <Resource name="host" list={ HostList } show={ HostShow } edit={ HostEdit } create={ HostCreate } />
        </Admin>
    );
}

export default App;
