import React from 'react';
import { useRedirect, Button, useNotify } from 'react-admin';
import EmailIcon from '@mui/icons-material/Email';

const AddByEmailsButton: React.FC<{ record: any }> = ({ record }) => {
    const redirect = useRedirect();
    const notify = useNotify();

    const handleClick = () => {
        if (!record || !record.id) {
            notify('Invalid entity ID', { type: 'warning' });
            return;
        }

        // Переходим на кастомную страницу с формой
        redirect(`/service/${record.id}/add-by-emails`);
    };

    return (
        <Button
            label="Add by emails"
            onClick={handleClick}
            startIcon={<EmailIcon />}
        />
    );
};

export default AddByEmailsButton;
