import {
    Datagrid,
    List,
    TextField,
    ArrayField,
    DateField,
    Show,
    SimpleShowLayout,
    ReferenceArrayField,
    ShowButton,
    SelectInput,
    TextInput
} from 'react-admin';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const accountFilters = [
    <SelectInput label="Status" source="status" choices={[
        {id: 'active', name: 'active'},
        {id: 'disabled', name: 'disabled'},
    ]}/>,
    <TextInput label="Name" source="q"/>,
];

export const AccountList = () => (
    <List filters={accountFilters}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="type"/>
            <TextField source="hosts_count"/>
            <TextField source="groups_count"/>
            <ReferenceArrayField source="services_ids" reference="service"/>
        </Datagrid>
    </List>
);

export const AccountShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="phone"/>
            <TextField source="emails"/>

            <ReferenceArrayField label="Services" reference="service" source="services_ids">
                <Datagrid bulkActionButtons={false} rowClick={false}>
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <ShowButton/>
                </Datagrid>
            </ReferenceArrayField>

            <Accordion>
                <AccordionSummary aria-controls="panel1-content" id="panel1-header">Properties</AccordionSummary>
                <AccordionDetails>
                    <ArrayField source="properties">
                        <Datagrid bulkActionButtons={false} rowClick={false}>
                            <TextField source="name"/>
                            <TextField source="values"/>
                        </Datagrid>
                    </ArrayField>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary aria-controls="panel2-content" id="panel2-header">Changes</AccordionSummary>
                <AccordionDetails>
                    <ArrayField source="changes">
                        <Datagrid bulkActionButtons={false} rowClick={false}>
                            <TextField source="name"/>
                            <TextField source="type"/>
                            <DateField source="changed_at"/>
                        </Datagrid>
                    </ArrayField>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary aria-controls="panel3-content" id="panel3-header">Groups</AccordionSummary>
                <AccordionDetails>
                    <ReferenceArrayField label="Groups" reference="group" source="groups_ids">
                        <Datagrid bulkActionButtons={false} rowClick={false}>
                            <TextField source="id"/>
                            <TextField source="title"/>
                            <ShowButton/>
                        </Datagrid>
                    </ReferenceArrayField>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary aria-controls="panel4-content" id="panel4-header">Hosts</AccordionSummary>
                <AccordionDetails>
                    <ReferenceArrayField label="Hosts" reference="host" source="hosts_ids">
                        <Datagrid bulkActionButtons={false} rowClick={false}>
                            <TextField source="id"/>
                            <TextField source="path"/>
                            <ShowButton/>
                        </Datagrid>
                    </ReferenceArrayField>
                </AccordionDetails>
            </Accordion>
        </SimpleShowLayout>
    </Show>
);
