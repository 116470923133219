import {
    Datagrid,
    List,
    ReferenceArrayField,
    TextField,
    Show,
    SimpleShowLayout,
    ShowButton,
    Edit,
    ReferenceArrayInput,
    SimpleForm,
    TextInput,
    AutocompleteArrayInput,
    Create
} from 'react-admin';

export const HostList = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="path" />
            <ReferenceArrayField source="accounts_ids" reference="account" />
        </Datagrid>
    </List>
);

export const HostShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="path" />
            <ReferenceArrayField label="Accounts" reference="account" source="accounts_ids">
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);

export const HostEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="path" />
            <ReferenceArrayInput source="accounts_ids" reference="account">
                <AutocompleteArrayInput />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

export const HostCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="path" />
            <ReferenceArrayInput source="accounts_ids" reference="account">
                <AutocompleteArrayInput />
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);
