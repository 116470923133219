import { DataProvider } from "ra-core";
import {
    CreateParams,
    CreateResult,
    DeleteManyParams,
    DeleteManyResult,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyReferenceParams,
    GetManyReferenceResult,
    GetManyResult,
    GetOneParams,
    GetOneResult, HttpError,
    Identifier,
    RaRecord,
    UpdateManyParams,
    UpdateManyResult,
    UpdateParams,
    UpdateResult
} from 'react-admin';

interface ErrorItem {
    status: string;
    detail: string;
    source: {
        pointer: string;
    };
    title: string;
}

interface ErrorObject {
    [key: string]: string | ErrorObject[] | {} | any;
}

function convertErrors(errors: { errors: ErrorItem[] }): ErrorObject {
    const result: ErrorObject = {errors: {}};

    function removeIndexNotation(str: string): string {
        // Replace the first occurrence of '[' and everything after it
        return str.replace(/\[.*?]/, '');
    }

    errors.errors.forEach(error => {
        const pointer = error.source.pointer;

        if (typeof pointer !== 'undefined' && pointer.includes('.')) {
            const pointerParts = pointer.split('.');
            const field = removeIndexNotation(pointerParts[0]);
            const indexMatch = pointer.match(/\[(\d+)\]/);

            if (indexMatch) {
                const index = parseInt(indexMatch[1]);
                if (!result.errors[field]) {
                    result.errors[field] = [];
                }
                while (result.errors[field].length <= index) {
                    result.errors[field].push({});
                }
                (result.errors[field][index] as any)[pointerParts[1]] = error.detail;
            } else {
                if (!result.errors[field]) {
                    result.errors[field] = [{}];
                }
                (result.errors[field][0] as any)[pointerParts[1]] = error.detail;
            }
        } else {
            if (!result.errors[pointer]) {
                result.errors[pointer] = error.detail;
            }
        }
    });

    return result;
}

const fetchJson = async (url: string, options: any = {}) => {
    const requestHeaders = (options.headers ||
        new Headers({
            Accept: 'application/json',
        })
    );

    if (!requestHeaders.has('Content-Type') &&
        !(options && options.body && options.body instanceof FormData)) {
        requestHeaders.set('Content-Type', 'application/json');
    }

    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set('Authorization', options.user.token);
    }

    const response = await fetch(url, {...options, headers: requestHeaders})
    const text = await response.text()
    const o = {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
    };

    let status = o.status, statusText = o.statusText, headers = o.headers, body = o.body;
    let json;

    try {
        json = JSON.parse(body);
    } catch (e) {
        // not json, no big deal
    }

    if (status < 200 || status >= 300) {
        return Promise.reject(new HttpError((json && json.error && json.error.message) || statusText, status, convertErrors(json)));
    }

    return Promise.resolve({status: status, headers: headers, body: body, json: json});
};

export class DashboardDataProvider implements DataProvider {
    private readonly countHeader = 'X-Total-Count';

    constructor(private readonly apiUrl: string, private readonly httpClient = fetchJson) {
    }

    create<RecordType, ResultRecordType extends RaRecord<Identifier>>(resource: string, params: CreateParams): Promise<CreateResult<ResultRecordType>> {
        return this.httpClient(`${this.apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}));
    }

    delete<RecordType extends RaRecord<Identifier>>(resource: string, params: DeleteParams<RecordType>): Promise<DeleteResult<RecordType>> {
        throw new Error();
    }

    deleteMany<RecordType extends RaRecord<Identifier>>(resource: string, params: DeleteManyParams<RecordType>): Promise<DeleteManyResult<RecordType>> {
        throw new Error();
    }

    getList<RecordType extends RaRecord<Identifier>>(resource: string, params: GetListParams): Promise<GetListResult<RecordType>> {
        // @ts-ignore
        const {page, perPage} = params.pagination;
        // const {field, order} = params.sort;

        let query = `page[size]=${perPage}&page[number]=${page}`;

        if (typeof params.filter !== 'undefined') {
            query += `&filters[equal]=${JSON.stringify(params.filter)}`
        }

        const url = `${this.apiUrl}/${resource}?${query}`;
        const options = {};

        return this.httpClient(url, options).then(({headers, json}) => {
            if (!headers.has(this.countHeader)) {
                throw new Error(
                    `The ${this.countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${this.countHeader} in the Access-Control-Expose-Headers header?`
                );
            }

            return {
                data: json,
                total: parseInt(headers.get(this.countHeader.toLowerCase()) ?? ''),
            };
        });
    }

    getMany<RecordType extends RaRecord<Identifier>>(resource: string, params: GetManyParams): Promise<GetManyResult<RecordType>> {
        let queryName = 'id';

        if (typeof params.meta !== 'undefined' && typeof params.meta.queryName !== 'undefined') {
            queryName = params.meta.queryName;
        }

        const isScalarArray = (test: any[]): boolean => {
            return Array.isArray(test) && (
                !test.some((value) => typeof value !== 'string')
                || !test.some((value) => typeof value !== 'number')
            );
        }

        let ids;

        if (isScalarArray(params.ids)) {
            ids = params.ids;
        } else {
            ids = params.ids.map(elm => {
                // @ts-ignore
                const convert = elm as { id: number | string };
                return convert.id;
            });
        }

        const query = "filters[in]=" + JSON.stringify({[queryName]: ids});
        const url = `${this.apiUrl}/${resource}?${query}`;

        return this.httpClient(url).then(({json}) => ({data: json}));
    }

    getManyReference<RecordType extends RaRecord<Identifier>>(resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult<RecordType>> {
        throw new Error('123');
    }

    getOne<RecordType extends RaRecord<Identifier>>(resource: string, params: GetOneParams<RecordType>): Promise<GetOneResult<RecordType>> {
        return this.httpClient(`${this.apiUrl}/${resource}/${params.id}`).then(({json}) => ({
            data: json,
        }));
    }

    update<RecordType extends RaRecord<Identifier>>(resource: string, params: UpdateParams): Promise<UpdateResult<RecordType>> {
        return this.httpClient(`${this.apiUrl}/${resource}/${params.id}`, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}));
    }

    updateMany<RecordType extends RaRecord<Identifier>>(resource: string, params: UpdateManyParams): Promise<UpdateManyResult<RecordType>> {
        throw new Error();
    }
}
