import {
    AutocompleteArrayInput,
    Create,
    Datagrid,
    Edit,
    List,
    ReferenceArrayField,
    ReferenceArrayInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    useRecordContext,
    useRedirect,
    TopToolbar,
    useResourceDefinition,
    EditButton,
    useNotify,
    useDataProvider
} from 'react-admin';
import React, { useState } from 'react';
import AddByEmailsButton from '../components/add-by-emails-button';
import { useParams } from 'react-router-dom';

const CustomToolbar: React.FC = () => {
    const record = useRecordContext();
    const {hasEdit, hasShow} = useResourceDefinition();

    if (!record) return null;

    return (
        <TopToolbar>
            <AddByEmailsButton record={record}/>
            {hasEdit && <EditButton record={record}/>}
            {hasShow && <ShowButton record={record}/>}
        </TopToolbar>
    );
};

export const ServiceList = () => (
    <List>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <ReferenceArrayField source="groups_ids" reference="group"/>
            <TextField source="accounts_count"/>
        </Datagrid>
    </List>
);

export const ServiceShow: React.FC = (props) => (
    <Show {...props} actions={<CustomToolbar/>}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <ReferenceArrayField label="Groups" reference="group" source="groups_ids">
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="title"/>
                    <ShowButton/>
                </Datagrid>
            </ReferenceArrayField>
            <ReferenceArrayField label="Accounts" reference="account" source="accounts_ids">
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <ShowButton/>
                </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);

export const ServiceEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name"/>
            <ReferenceArrayInput source="groups_ids" reference="group">
                <AutocompleteArrayInput/>
            </ReferenceArrayInput>
            <ReferenceArrayInput source="direct_accounts_ids" reference="account">
                <AutocompleteArrayInput/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

export const ServiceCreate = () => {
    const redirect = useRedirect();

    const handleSuccess = () => {
        redirect('list', 'service');
    };

    return (
        <Create mutationOptions={{onSuccess: handleSuccess}}>
            <SimpleForm>
                <TextInput source="name"/>
                <ReferenceArrayInput source="groups_ids" reference="group">
                    <AutocompleteArrayInput/>
                </ReferenceArrayInput>
                <ReferenceArrayInput source="direct_accounts_ids" reference="account">
                    <AutocompleteArrayInput/>
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
};

export const AddByEmailsForm: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [emails, setEmails] = useState('');
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const handleSubmit = async () => {
        try {
            await dataProvider.create('addUserToServiceByEmails', {
                data: {
                    id,
                    emails,
                },
            });
            notify('Emails added successfully', { type: 'success' });
            redirect(`/service/${id}/show`);
        } catch (error) {
            notify('Error adding emails', { type: 'error' });
        }
    };

    return (
        <Create resource="service">
            <SimpleForm onSubmit={handleSubmit}>
                <TextInput
                    label="Emails"
                    multiline
                    source="emails"
                    value={emails}
                    onChange={(e) => setEmails(e.target.value)}
                />
            </SimpleForm>
        </Create>
    );
};
